import React, { useEffect } from 'react';

import { NavLinkText, NavLinkWrapper, SubNavIcon } from './styles';
import { Popover } from '@mui/material';
import { CaretDown } from '../../../assets/staticIcons';
import SubNavPopover from '../SubNavPopover';
import { NavItemProps } from '../NavItem';
import isNavLinkActive from '../../../utils/isNavLinkActive';

const SubNavItem: React.FC<NavItemProps> = ({ navItem, currentPath, hide }) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);

  // hide sub nav when the header is hidden on scroll
  useEffect(() => {
    if (hide) {
      handleClose();
    }
  }, [hide]);

  const anchorRef = React.useRef<HTMLDivElement>(null);
  const popoverRef = React.useRef<HTMLDivElement>(null);

  const handleOpen = () => {
    setAnchorEl(anchorRef.current);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <div onMouseEnter={handleOpen} onMouseLeave={handleClose}>
      <NavLinkWrapper
        onClick={handleOpen}
        className={`navLink subnav ${
          isNavLinkActive(currentPath, navItem) ? 'active' : ''
        }`}
      >
        <NavLinkText ref={anchorRef}>
          {navItem.displayText}
          <div className="square"></div>
        </NavLinkText>
        <SubNavIcon isOpen={open}>
          <CaretDown />
        </SubNavIcon>
      </NavLinkWrapper>
      <Popover
        id={`${navItem.contentful_id}-popover`}
        open={open}
        anchorReference="anchorPosition"
        anchorPosition={{
          left: 0,
          top: 60,
        }}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        disableRestoreFocus={true}
        disableScrollLock={true}
        transformOrigin={{ horizontal: 'left', vertical: 16 }}
        hideBackdrop={true}
        keepMounted={true}
        slotProps={{
          root: {
            style: {
              top: '60px',
              left: (anchorRef.current?.getBoundingClientRect().x || 0) - 16,
              width:
                (popoverRef.current?.getBoundingClientRect().width || 0) + 120,
              height:
                (popoverRef.current?.getBoundingClientRect().height || 0) + 120,
            },
          },
          paper: {
            style: {
              background: 'none',
              boxShadow: 'none',
              maxHeight: '100%',
            },
          },
        }}
      >
        <SubNavPopover
          currentPath={currentPath}
          navItems={navItem.subNavLinks}
          subNavOverview={navItem.subNavOverview}
          handleOnClick={handleClose}
          subnavRef={popoverRef}
        />
      </Popover>
    </div>
  );
};

export default SubNavItem;
