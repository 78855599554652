import React, { ReactNode } from 'react';

import { INavLink } from '../../../types/contentfulContentTypes';
import ContentfulImage from '../../ContentfulImage';
import NavLink from '../NavLink';
import SectionWrapper from '../SectionWrapper';
import {
  CopyrightText,
  MainFooter,
  LogoStyle,
  TabletNavLink,
  PhoneNumberTextStyle,
  AddressTextStyle,
  NavLinksStyle,
  FooterGrid,
  FooterWrapper,
  Right,
  Left,
  MobileNavLink,
} from './styles';

interface FooterProps {
  footerLogoNavLink: INavLink;
  addressText: string;
  phoneNumber: string;
  navigationText: ReactNode;
  navLinks: INavLink[];
  legalLinks: INavLink[];
  hideOnDesktop?: boolean;
  email: string;
  copyrightText: string;
}

const Footer: React.FC<FooterProps> = (props) => {
  const {
    addressText,
    footerLogoNavLink,
    hideOnDesktop,
    phoneNumber,
    navLinks,
    copyrightText,
  } = props;

  const navItems = navLinks.map((navItem) => {
    return (
      <NavLink
        link={navItem}
        key={navItem.id}
        title={navItem.displayText ?? 'Thrillworks'}
      >
        <ContentfulImage image={navItem.icon} alt={navItem.displayText} />
      </NavLink>
    );
  });

  const currentYear = new Date().getFullYear();

  return (
    <MainFooter hideOnDesktop={hideOnDesktop}>
      <FooterWrapper>
        <FooterGrid>
          <Right>
            <LogoStyle>
              <NavLink link={footerLogoNavLink}>
                <span className="sr-only">{footerLogoNavLink.displayText}</span>
                <ContentfulImage
                  image={footerLogoNavLink.icon}
                  alt={footerLogoNavLink.displayText}
                />
              </NavLink>
            </LogoStyle>
            <MobileNavLink>
              <NavLinksStyle>{navItems}</NavLinksStyle>
            </MobileNavLink>
          </Right>
          <Left>
            <TabletNavLink>
              <NavLinksStyle>{navItems}</NavLinksStyle>
            </TabletNavLink>
            <PhoneNumberTextStyle>
              <a href={`tel:${phoneNumber}`}>{phoneNumber}</a>
            </PhoneNumberTextStyle>
            <AddressTextStyle>{addressText}</AddressTextStyle>
            <CopyrightText>
              &copy; {currentYear} &mdash; {copyrightText}
            </CopyrightText>
          </Left>
        </FooterGrid>
      </FooterWrapper>
    </MainFooter>
  );
};

export default Footer;
