import './src/styles/global.css';
import React from 'react';
import Layout from './src/components/Layout';
import MicrositeLayout from './src/components/MicrositeLayout';
import { GatsbyBrowser } from 'gatsby';
import * as ReactDOM from 'react-dom/client';

export const wrapPageElement: GatsbyBrowser<any>['wrapPageElement'] = ({
  element,
  props,
}) => {
  return props.data.contentfulV2MicrositePage ? (
    <MicrositeLayout {...props}>{element as any}</MicrositeLayout>
  ) : (
    <Layout {...props}>{element as any}</Layout>
  );
};

export const shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition,
}) => {
  const { pathname } = location;
  // list of routes for the scroll-to-top-hook
  const scrollToTopRoutes = [`/approach`];
  // if the new route is part of the list above, scroll to top (0, 0)
  if (scrollToTopRoutes.indexOf(pathname) !== -1) {
    window.scrollTo(0, 0);
  } else {
    // if the route is not part of the list above, get the savedPosition
    const savedPosition = getSavedScrollPosition(location);
    window.scrollTo(...(savedPosition || [0, 0]));
  }

  return false;
};

// This replaces the default hydration function with a function that takes a container, created a root node from it and then attaches the child element.
// 1) https://www.gatsbyjs.com/docs/conceptual/react-hydration/#the-reactdomclienthydrateroot-method
// 2) https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/#replaceHydrateFunction
export const replaceHydrateFunction = () => {
  return (element: React.ReactNode, container: ReactDOM.Container) => {
    // Documentation around createRoot:  https://react.dev/reference/react-dom/client/createRoot
    const root = ReactDOM.createRoot(container);
    root.render(element);
  };
};
