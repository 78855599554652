// Takes a URL string and checks to see that it has either "http://" or "https://", or "mailto" or "tel" in it to render a standard URL

import { INavLink } from '../types/contentfulContentTypes';

// If not, adds it to prevent Gatsby from rendering external links as relative links
export default (currentPath: string, navItem: INavLink): boolean => {
  if (navItem.hasSubNavigation === 'Yes') {
    return (
      currentPath.startsWith(`${navItem.subNavOverview?.linkPage?.pageSlug}`) ||
      currentPath.startsWith(`/${navItem.subNavOverview?.linkPage?.pageSlug}`)
    );
  } else {
    return (
      currentPath === navItem.linkPage?.pageSlug ||
      currentPath === `/${navItem.linkPage?.pageSlug}`
    );
  }
};
