import React, { useState, useEffect, useRef } from 'react';
import Lottie from 'lottie-react';
import { CSSTransition } from 'react-transition-group';
import animatedLogoLight from '../../../assets/images/animated-tw-logo-light.json';
import animatedLogoDark from '../../../assets/images/animated-tw-logo-dark.json';
import MobileMenu from '../../UI/MobileMenu';
import ContentfulImage from '../../ContentfulImage';

import {
  DownloadText,
  DownloadIcon,
  MainHeader,
  LogoWrapper,
  MobileLogo,
  DefaultLogo,
  HeaderTextWrapper,
  HeaderText,
  HeaderContentWrapper,
} from './styles';

export interface HeaderProps {
  data: {
    mainLogo: object;
    navigationMenus: any[];
    supportingText: string;
  };
  isHomePage: boolean;
  currentPath: string;
  mobileLogoColour: 'light' | 'dark';
  navLinks: any;
}

const Header: React.FC<HeaderProps> = ({
  data,
  isHomePage,
  currentPath,
  mobileLogoColour,
}) => {
  // Mobile menu status
  const [isMenuOpen, updateIsMenuOpen] = useState(false);

  // Pop in/out scroll behaviour for header
  const [scrollClassName, setScrollClassName] = useState('');
  useEffect(() => {
    let lastScroll = 0;
    const scrollListener = () => {
      const currentScroll = window.pageYOffset;
      if (currentScroll <= 0) {
        setScrollClassName('');
        return;
      }
      if (currentScroll > lastScroll) {
        setScrollClassName('scroll-down');
      } else if (currentScroll < lastScroll) {
        setScrollClassName('');
      }
      lastScroll = currentScroll;
    };
    window.addEventListener('scroll', scrollListener);
    return () => {
      window.removeEventListener('scroll', scrollListener);
    };
  }, []);

  // This ref is used in the mobile menu focus trap to include it in the tab order
  const headerHamburgerRef = useRef(null);

  // Determine logo colour to use for mobile based on CMS value chosen
  const animatedLogo =
    mobileLogoColour === 'dark' ? animatedLogoDark : animatedLogoLight;

  const downloadLink = data.navigationMenus[0].navLinks[0];

  return (
    <>
      <MainHeader
        className={`${
          isHomePage ? 'homePage' : 'standardPage'
        } ${scrollClassName} ${isMenuOpen ? 'menuOpen' : ''}`}
      >
        <HeaderContentWrapper>
          {/* Logo */}
          <LogoWrapper className={isHomePage ? 'homePage' : 'standardPage'}>
            <MobileLogo to={'/'}>
              <span className="sr-only">Thrillworks</span>
              <Lottie animationData={animatedLogo} loop={false} />
            </MobileLogo>
            <DefaultLogo to={'/'}>
              <span className="sr-only">Thrillworks</span>
              <Lottie animationData={animatedLogoLight} loop={false} />
            </DefaultLogo>
          </LogoWrapper>
          <HeaderTextWrapper>
            <HeaderText>{data.supportingText}</HeaderText>
            <DownloadText to={downloadLink.linkUrl}>
              {downloadLink.displayText}
              <DownloadIcon>
                <ContentfulImage
                  image={downloadLink.icon}
                  alt={downloadLink.icon.description}
                />
              </DownloadIcon>
            </DownloadText>
          </HeaderTextWrapper>
        </HeaderContentWrapper>
      </MainHeader>

      {/* Mobile menu */}
      <CSSTransition
        in={isMenuOpen}
        timeout={500}
        classNames="mobileMenu"
        unmountOnExit
      >
        <MobileMenu
          currentPath={currentPath}
          isMenuOpen={isMenuOpen}
          clickHandler={() => updateIsMenuOpen(!isMenuOpen)}
          focusTrapRef={headerHamburgerRef}
        />
      </CSSTransition>
    </>
  );
};

export default Header;
