import styled from 'styled-components';
import twTheme from '../../../styles/theme';

export const NavLinkWrapper = styled.button`
  display: flex;
  gap: 8px;
  background: none;
  border: none;
  padding: 0;

  ${twTheme.fontStyle.desktop.caption};
  color: ${twTheme.colour.white};
  text-decoration: none;

  &:active,
  &:active,
  &.active .square {
    visibility: visible;
  }
  &:hover,
  &.active {
    color: ${twTheme.colour.mint};
  }
`;

export const NavLinkText = styled.span`
  display: flex;
  flex-direction: column;
  gap: 3px;
  align-items: center;

  .square {
    visibility: hidden;
    height: 6px;
    width: 6px;
    background-color: ${twTheme.colour.mint};
  }
`;

export const SubNavIcon = styled.span<{ isOpen?: boolean }>`
  margin-top: 2px;
  width: 14px;
  height: 14px;
  cursor: pointer;

  transform: rotate(${({ isOpen }) => (isOpen ? '180deg' : '0')});
`;
