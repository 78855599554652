import styled from 'styled-components';
import { Link } from 'gatsby';
import { AnchorLink } from 'gatsby-plugin-anchor-links';

import twTheme from '../../../styles/theme';

export const NavLinksStyle = styled.div`
  display: none;

  @media screen and (min-width: ${twTheme.screens.tablet}) {
    width: 32px;
    display: flex;
    gap: 24px;
    justify-content: center;
    align-items: center;

    a {
      width: 24px;
      height: 24px;
      @media screen and (min-width: ${twTheme.screens.desktop}) {
        width: 32px;
        height: 32px;
      }

      * {
        fill: ${twTheme.colour.white};
      }
    }
  }
`;

export const MainHeader = styled.header`
  position: absolute;
  width: 100%;
  background-color: transparent;
  z-index: 50;
  &.homePage {
    height: 280px;
    background-color: rgba(15, 40, 48, 0.65);
  }
  /* === */
  @media screen and (min-width: ${twTheme.screens.tablet}) {
    /* For scroll effect */
    &.scroll-down {
      transform: translate3d(0, -100%, 0);
    }
    &.homePage,
    &.standardPage {
      position: fixed;
      top: 0;
      left: 0;
      background-color: #222222;
      backdrop-filter: blur(10px);
      transition: all 300ms ease-in-out;
      z-index: 100;
    }
  }
`;

export const LogoWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  &.homePage {
    flex-wrap: wrap;
    width: 100%;
    height: 120px;
    @media screen and (max-width: 374px) {
      align-items: flex-start;
      margin-top: 10px;
    }
  }
  @media screen and (min-width: ${twTheme.screens.tablet}) {
    &.standardPage,
    &.homePage {
      flex-wrap: nowrap;
      height: auto;
    }
  }
  @media screen and (min-width: 800px) and (max-width: 1000px) {
    &.standardPage,
    &.homePage {
      flex-wrap: nowrap;
      height: auto;
    }
  }
`;

export const MobileLogo = styled(Link)`
  height: auto;
  max-width: 143px;
  margin-right: 30px;
  @media screen and (min-width: ${twTheme.screens.tablet}) {
    display: none;
  }
`;

export const DefaultLogo = styled(Link)`
  display: none;
  @media screen and (min-width: ${twTheme.screens.tablet}) {
    display: block;
    max-width: 174px;
    margin-right: 30px;
  }
  @media screen and (min-width: ${twTheme.screens.desktop}) {
    height: auto;
    max-width: 176px;
  }
`;
export const HeaderTextWrapper = styled.div`
  margin-top: ${twTheme.spacing.x6};
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  @media screen and (min-width: ${twTheme.screens.tablet}) {
    margin-top: 0;
    row-gap: 0;
    column-gap: 35px;
    flex-direction: row;
    align-items: center;
  }
`;

export const Divider = styled.div`
  /* line animation effects */
  animation: headerAnimation1 0.8s cubic-bezier(0.82, 0, 0.22, 1) forwards;
  @keyframes headerAnimation1 {
    0% {
      height: 0px;
      transform: translateY(-25px);
    }
    100% {
      height: 100%;
      transform: translateY(0);
    }
  }
  /* === */
  height: 100%;
  width: 2px;
  background-color: ${twTheme.colour.tangerine};
`;

export const HeaderText = styled.div`
  color: #f4f4f4;
  ${twTheme.fontStyle.mobile.body1};
`;

export const DownloadLinkWrapper = styled.div``;

export const DownloadText = styled(AnchorLink)`
  text-decoration: underline;
  color: ${twTheme.colour.tangerine};
  ${twTheme.fontStyle.mobile.body1};
  display: flex;
  align-items: center;
  column-gap: 11px;
`;

export const DownloadIcon = styled.div`
  width: 20px;
  height: 20px;

  @media screen and (min-width: ${twTheme.screens.tablet}) {
    width: 32px;
    height: 32px;
  }
`;

export const HeaderContentWrapper = styled.div`
  max-width: ${twTheme.screens.maxViewport};
  margin: auto;
  position: relative;
  padding: 20px;

  @media screen and (min-width: ${twTheme.screens.tablet}) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 34px 63px;
  }
`;
