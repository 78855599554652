import styled from 'styled-components';

import twTheme from '../../../styles/theme';
import NavLink from '../../NavLink';

export const SubNavContainer = styled.div`
  align-items: start;
  border-radius: 20px;
  backdrop-filter: blur(25px);
  background-color: ${twTheme.colour.black};
  display: flex;
  min-width: 344px;
  flex-direction: column;
  font-size: 16px;
  color: ${twTheme.colour.white};
  font-weight: 400;
  line-height: 110%;
  padding: 12px 0;
`;

export const SubNavItem = styled(NavLink)`
  font-family: Roboto, sans-serif;
  display: flex;
  gap: 10px;
  padding: 16px 24px;
  ${twTheme.fontStyle.desktop.caption};
  color: ${twTheme.colour.white};
  text-decoration: none;
  .square {
    display: none;
    height: 6px;
    width: 6px;
    margin: 6px auto 0 auto;
    background-color: ${twTheme.colour.mint};
  }
  &.active .square {
    display: inline-flex;
  }
  &:hover,
  &.active {
    color: ${twTheme.colour.mint};
  }
  &.child {
    padding-left: 60px;
  }
`;
