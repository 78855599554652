import styled from 'styled-components';

import twTheme from '../../../styles/theme';

export const NavMenuButton = styled.button`
  border: none;
  background-color: transparent;
  .hamburgerWrapper {
    width: 24px;
    height: 24px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    transition: all 0.3s;
    &.menuOpen {
      justify-content: space-between;
    }
    .hamburgerBar {
      height: 3px;
      width: 100%;
      background: ${twTheme.colour.white};
      transition: all 0.3s;
    }

    &.menuOpen .hamburgerBar#topBar {
      background: ${twTheme.colour.mint};
      transform: rotate(45deg) translate(3px, 12px);
      transform-origin: center;
      width: 30px;
      margin: 0;
    }
    &.menuOpen .hamburgerBar#bottomBar {
      background: ${twTheme.colour.mint};
      transform: rotate(-45deg) translate(2.5px, -12px);
      transform-origin: center;
      width: 30px;
      margin: 0;
    }
  }
`;
