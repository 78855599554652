import React, { useEffect, useRef, useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import NavLink from '../../NavLink';

import FocusTrap from 'focus-trap-react';

import {
  MobileMenuContainer,
  NavItemsWrapper,
  NavItemContainer,
  NavLinkWrapper,
  SubNavLinkWrapper,
  SubNavIcon,
} from './styles';
import { CaretDown } from '../../../assets/staticIcons';
import { INavLink } from '../../../types/contentfulContentTypes';
import isNavLinkActive from '../../../utils/isNavLinkActive';

// The mobile menu displays on click of the hamburger icon via header or footer
export interface MobileMenuProps {
  currentPath: string;
  isMenuOpen: boolean;
  clickHandler(): any;
  focusTrapRef: any;
  logoRef: any;
}

const MobileMenu: React.FC<MobileMenuProps> = ({
  currentPath,
  isMenuOpen,
  clickHandler,
  focusTrapRef,
  logoRef,
}) => {
  const [subMenuOpenId, setSubMenuOpenId] = useState<string>();
  const [animationClass, setAnimationClass] = useState<string>('');
  const openSubMenu = (menuId?: string) => {
    if (subMenuOpenId) {
      setSubMenuOpenId('');
    } else {
      setSubMenuOpenId(menuId);
    }
  };

  // Mobile Menu query
  const mobileMenuData = useStaticQuery(graphql`
    query {
      contentfulV2NavMenu(contentful_id: { eq: "54mOBYxTkXEfkzHa6icVHr" }) {
        navLinks {
          ...NavLinkFragment
        }
      }
    }
  `);
  const { contentfulV2NavMenu } = mobileMenuData;

  const SubNavItem = ({ navItem }: { navItem: INavLink }) => (
    <NavItemContainer hasSubNav onClick={() => openSubMenu(navItem.id)}>
      <NavLinkWrapper hasSubNav>
        <div
          className={`navLink ${
            isNavLinkActive(currentPath, navItem) ? 'active' : ''
          }`}
        >
          {navItem.displayText}
        </div>
        <SubNavIcon
          className={`${isNavLinkActive(currentPath, navItem) ? 'active' : ''}`}
          isOpen={subMenuOpenId === navItem.id}
        >
          <CaretDown />
        </SubNavIcon>
      </NavLinkWrapper>
      <SubNavLinkWrapper isOpen={subMenuOpenId === navItem.id}>
        {navItem.subNavOverview && (
          <NavLink
            link={navItem.subNavOverview}
            key={navItem.subNavOverview.id}
            className={`navLink ${
              isNavLinkActive(currentPath, navItem.subNavOverview)
                ? 'active'
                : ''
            }`}
            onClick={clickHandler}
          >
            {navItem.subNavOverview.displayText}
          </NavLink>
        )}
        {navItem.subNavLinks?.map((subNavItem: INavLink) => (
          <NavLink
            link={subNavItem}
            key={subNavItem.id}
            className={`navLink ${
              isNavLinkActive(currentPath, subNavItem) ? 'active' : ''
            }`}
            onClick={clickHandler}
          >
            {subNavItem.displayText}
          </NavLink>
        ))}
      </SubNavLinkWrapper>
    </NavItemContainer>
  );

  // Create nav items from CMS data
  const navItems = contentfulV2NavMenu.navLinks.map((navItem: INavLink) => {
    return navItem.hasSubNavigation === 'Yes' ? (
      <SubNavItem key={navItem.id} navItem={navItem} />
    ) : (
      <NavItemContainer key={navItem.id}>
        <NavLinkWrapper>
          <NavLink
            link={navItem}
            key={navItem.id}
            className={`navLink ${
              isNavLinkActive(currentPath, navItem) ? 'active' : ''
            }`}
            onClick={clickHandler}
          >
            {navItem.displayText}
          </NavLink>
        </NavLinkWrapper>
      </NavItemContainer>
    );
  });

  // Add event listener while menu is open to enable esc to close menu
  useEffect(() => {
    const escapeTrigger = (e: KeyboardEvent) => {
      e.key === 'Escape' && clickHandler();
    };
    window.addEventListener('keydown', escapeTrigger);
    return () => {
      window.removeEventListener('keydown', escapeTrigger);
    };
  }, []);

  // * prevent the body from being scrolled while the mobile menu is open
  useEffect(() => {
    if (typeof document === 'undefined') return;
    if (isMenuOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [isMenuOpen]);

  useEffect(() => {
    if (isMenuOpen) {
      setAnimationClass('mobileMenu-open');
    } else {
      setAnimationClass('mobileMenu-close');
    }
  }, [isMenuOpen]);

  // Open the menu if the current page is active
  useEffect(() => {
    contentfulV2NavMenu.navLinks.map((navItem: INavLink) => {
      if (
        navItem.hasSubNavigation === 'Yes' &&
        isNavLinkActive(currentPath, navItem)
      ) {
        setSubMenuOpenId(navItem.id);
      }
    });
  }, []);

  // Focus trap accepts refs as a prop to allow inclusion of items that are not children of the <FocusTrap> element
  // In this case this let's us add the hamburger menu icon to the focus trap
  const menuRef = useRef(null);

  return (
    <FocusTrap
      containerElements={[
        menuRef.current,
        focusTrapRef.current,
        logoRef.current,
      ]}
    >
      <MobileMenuContainer ref={menuRef} className={animationClass}>
        <NavItemsWrapper>{navItems}</NavItemsWrapper>
      </MobileMenuContainer>
    </FocusTrap>
  );
};

export default MobileMenu;
