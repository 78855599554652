import styled, { css } from 'styled-components';
import twTheme from '../../../styles/theme';

export const MainFooter = styled.footer<{ hideOnDesktop?: boolean }>`
  ${twTheme.fontStyle.desktop.caption};
  background-color: ${twTheme.colour.darkGrey};
  @media screen and (min-width: ${twTheme.screens.desktop}) {
    ${(props) =>
      props.hideOnDesktop
        ? css`
            display: none;
          `
        : ''}
  }
  color: ${twTheme.colour.white};
  position: relative;
`;

export const LogoStyle = styled.div`
  max-width: 174px;
  width: 100%;

  @media screen and (min-width: ${twTheme.screens.tablet}) {
    max-width: 294px;
    margin: auto 0;
  }
`;

export const PhoneNumberTextStyle = styled.div`
  ${twTheme.fontStyle.mobile.body1}
  margin-top: 16px;

  a {
    color: ${twTheme.colour.white};
  }

  @media screen and (min-width: ${twTheme.screens.tablet}) {
    margin-top: 0;
    ${twTheme.fontStyle.desktop.body1}
  }
`;

export const AddressTextStyle = styled.div`
  ${twTheme.fontStyle.mobile.body1}
  margin-top: ${twTheme.spacing.sm};

  @media screen and (min-width: ${twTheme.screens.tablet}) {
    margin-top: 0;
    ${twTheme.fontStyle.desktop.body1}
  }

  @media screen and (min-width: ${twTheme.screens.desktop}) {
    text-align: left;
  }
`;

export const CopyrightText = styled.div`
  ${twTheme.fontStyle.mobile.caption}
  margin-top: ${twTheme.spacing.x15};

  @media screen and (min-width: ${twTheme.screens.tablet}) {
    margin-top: 0;
    margin-left: auto;
  }
`;

export const NavLinksStyle = styled.div`
  display: flex;
  gap: 24px;
  justify-content: center;
  margin-top: 8px;

  a {
    width: 24px;
    height: 24px;
    @media screen and (min-width: ${twTheme.screens.desktop}) {
      width: 32px;
      height: 32px;
    }

    * {
      fill: ${twTheme.colour.white};
    }
  }
`;

export const FooterGrid = styled.div`
  @media screen and (min-width: ${twTheme.screens.desktop}) {
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
  }

  .textWrapper {
    display: flex;
    flex-direction: column;
    text-align: center;
    @media screen and (min-width: ${twTheme.screens.desktop}) {
      text-align: left;
    }
  }
`;

export const FooterWrapper = styled.div`
  max-width: ${twTheme.screens.maxViewport};
  margin: auto;
  position: relative;
  padding: 40px 20px 20px;

  @media screen and (min-width: ${twTheme.screens.tablet}) {
    padding: 90px 60px;
  }

  .sr-only {
    color: ${twTheme.colour.white};
  }
`;

export const Right = styled.div`
  display: flex;
  justify-content: space-between;
`;
export const Left = styled.div`
  margin-left: 2px;
  margin-top: ${twTheme.spacing.x8};

  @media screen and (min-width: ${twTheme.screens.tablet}) {
    display: flex;
    align-items: center;
    column-gap: ${twTheme.spacing.x15};
    margin-top: ${twTheme.spacing.x16};
  }
`;

export const MobileNavLink = styled.div`
  @media screen and (min-width: ${twTheme.screens.tablet}) {
    display: none;
  }
`;

export const TabletNavLink = styled.div`
  display: none;
  @media screen and (min-width: ${twTheme.screens.tablet}) {
    display: block;
    margin-top: -8px;

    a {
      width: 24px;
      height: 24px;
      @media screen and (min-width: ${twTheme.screens.tablet}) {
        width: 32px;
        height: 32px;
      }

      * {
        fill: ${twTheme.colour.white};
      }
    }
  }
`;
