import React from 'react';

import { NavMenuButton } from './styles';

export interface MenuIconProps {
  menuStatus: 'menuOpen' | '';
  clickHandler(): any;
}

// A two line hamburger menu icon for use in nav bars
// It transforms to an "X" on hover or when the mobile menu is open
const MenuIcon: React.FC<MenuIconProps> = ({ menuStatus, clickHandler }) => {
  return (
    <NavMenuButton onClick={clickHandler} aria-label="Navigation menu toggle">
      <div className={`hamburgerWrapper ${menuStatus}`}>
        <div className="hamburgerBar" id="topBar" />
        <div className="hamburgerBar" id="bottomBar" />
      </div>
    </NavMenuButton>
  );
};

export default MenuIcon;
