import styled from 'styled-components';
import twTheme from '../../../styles/theme';
import NavLink from '../../NavLink';

export const NavLinkWrapper = styled(NavLink)`
  display: flex;
  gap: 8px;

  ${twTheme.fontStyle.desktop.caption};
  color: ${twTheme.colour.white};
  text-decoration: none;

  &:active,
  &:active,
  &.active .square {
    visibility: visible;
  }
  &:hover,
  &.active {
    color: ${twTheme.colour.mint};
  }
  &:nth-of-type(1) {
    animation: text-clip 1s 0.05s cubic-bezier(0.5, 0, 0.1, 1) both;
  }
  &:nth-of-type(2) {
    animation: text-clip 1s 0.15s cubic-bezier(0.5, 0, 0.1, 1) both;
  }
  &:nth-of-type(3) {
    animation: text-clip 1s 0.25s cubic-bezier(0.5, 0, 0.1, 1) both;
  }
  &:nth-of-type(4) {
    animation: text-clip 1s 0.35s cubic-bezier(0.5, 0, 0.1, 1) both;
  }
  &:nth-of-type(5) {
    animation: text-clip 1s 0.45s cubic-bezier(0.5, 0, 0.1, 1) both;
  }
`;

export const NavLinkText = styled.span`
  display: flex;
  flex-direction: column;
  gap: 3px;
  align-items: center;

  .square {
    visibility: hidden;
    height: 6px;
    width: 6px;
    background-color: ${twTheme.colour.mint};
  }
`;
