import styled from 'styled-components';
import twTheme from './theme';

export const AnimatedLinks = styled.span`
  a,
  button {
    text-decoration: none;
    display: inline-block;
    position: relative;
  }

  &.light a,
  &.light button {
    color: ${twTheme.colour.mint};
  }

  &.dark a,
  &.dark button {
    color: ${twTheme.colour.emerald};
  }

  a:after,
  button:after {
    position: absolute;
    content: '';
    width: 100%;
    transform: scaleX(1);
    height: 1px;
    bottom: 0;
    left: 0;
  }

  &.light a:after,
  &.light button:after {
    background-color: ${twTheme.colour.mint};
  }

  &.dark a:after,
  &.dark button:after {
    background-color: ${twTheme.colour.emerald};
  }

  a:hover:after,
  button:hover:after {
    animation: moveUnderline 1s cubic-bezier(0.82, 0, 0.22, 1);
  }

  &.light a:hover:after,
  &.light button:hover:after {
    background-color: ${twTheme.colour.white};
  }

  &.dark a:hover:after,
  &.dark button:hover:after {
    background-color: ${twTheme.colour.black};
  }

  @keyframes moveUnderline {
    0% {
      transform: scaleX(1);
      transform-origin: bottom right;
    }
    49.99% {
      transform: scaleX(0);
      transform-origin: bottom right;
    }
    50% {
      transform: scaleX(0);
      transform-origin: bottom left;
    }
    100% {
      transform: scaleX(1);
      transform-origin: bottom left;
    }
  }
`;
