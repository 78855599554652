import React from 'react';

import { NavLinkText, NavLinkWrapper } from './styles';
import { INavLink } from '../../../types/contentfulContentTypes';
import isNavLinkActive from '../../../utils/isNavLinkActive';

export interface NavItemProps {
  navItem: INavLink;
  currentPath: string;
  hide?: boolean;
  onClick?: () => void;
}

const NavItem: React.FC<NavItemProps> = ({ navItem, currentPath, onClick }) => {
  return (
    <NavLinkWrapper
      link={navItem}
      className={`navLink ${
        isNavLinkActive(currentPath, navItem) ? 'active' : ''
      }`}
      onClick={onClick}
    >
      <NavLinkText>
        {navItem.displayText}
        <div className="square"></div>
      </NavLinkText>
    </NavLinkWrapper>
  );
};

export default NavItem;
