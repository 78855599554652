import styled, { css } from 'styled-components';
import twTheme from '../../styles/theme';

export const MainFooter = styled.footer<{ hideOnDesktop?: boolean }>`
  ${twTheme.fontStyle.desktop.caption};
  background-color: ${twTheme.colour.forest};
  @media screen and (min-width: ${twTheme.screens.desktop}) {
    ${(props) =>
      props.hideOnDesktop
        ? css`
            display: none;
          `
        : ''}
  }
  color: ${twTheme.colour.white};
  position: relative;
`;

export const FooterTextStyle = styled.div`
  color: ${twTheme.colour.lilac};
  text-transform: uppercase;
  font-family: Roboto;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 1.25px;
  margin-top: 16px;

  @media screen and (min-width: ${twTheme.screens.desktop}) {
    font-size: 16px;
    line-height: 21px;
  }
`;

export const LogoStyle = styled.div`
  max-width: 200px;
  margin: auto;
  width: 100%;

  @media screen and (min-width: ${twTheme.screens.desktop}) {
    max-width: 314px;
    margin: auto 0;
  }
`;

export const PhoneNumberTextStyle = styled.div`
  ${twTheme.fontStyle.mobile.caption}
  margin-top: 20px;

  a {
    color: ${twTheme.colour.white};
  }

  @media screen and (min-width: ${twTheme.screens.tablet}) {
    margin-top: 32px;
  }
`;

export const AddressTextStyle = styled.div`
  ${twTheme.fontStyle.mobile.caption}
  margin-top: 12px;
`;

export const NavLinksStyle = styled.div`
  display: flex;
  gap: 24px;
  justify-content: center;
  margin-top: 32px;

  a {
    width: 24px;
    height: 24px;
    @media screen and (min-width: ${twTheme.screens.desktop}) {
      width: 32px;
      height: 32px;
    }

    * {
      fill: ${twTheme.colour.white};
    }
  }

  @media screen and (min-width: ${twTheme.screens.desktop}) {
    justify-content: left;
    margin-top: 48px;
  }
`;

export const LegalLinksStyle = styled.div`
  ${twTheme.fontStyle.desktop.caption}
  display: flex;
  text-align: center;
  gap: 16px;
  margin-top: 28px;
  flex-wrap: wrap;
  justify-content: center;

  a {
    color: ${twTheme.colour.lilac};
  }

  @media screen and (min-width: ${twTheme.screens.desktop}) {
    justify-content: left;
  }
`;

export const NavigationTextStyle = styled.div`
  ${twTheme.fontStyle.desktop.heading6}
  display: none;
  @media screen and (min-width: ${twTheme.screens.desktop}) {
    display: block;
    white-space: nowrap;
  }
`;

export const FooterGrid = styled.div`
  @media screen and (min-width: ${twTheme.screens.desktop}) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
  }

  .textWrapper {
    display: flex;
    flex-direction: column;
    text-align: center;
    @media screen and (min-width: ${twTheme.screens.desktop}) {
      text-align: left;
    }
  }
`;

export const Glyph = styled.div<{ glyphPosition: 'left' | 'right' }>`
  position: absolute;
  top: 24px;
  ${(props) => props.glyphPosition}: 24px;
  @media screen and (min-width: ${twTheme.screens.tablet}) {
    ${(props) => props.glyphPosition}: 60px;
  }
  @media screen and (min-width: ${twTheme.screens.desktop}) {
    top: 40px;
  }

  svg {
    fill: ${twTheme.colour.lilac};
  }
`;

export const FooterWrapper = styled.div`
  max-width: ${twTheme.screens.maxViewport};
  margin: auto;
  position: relative;

  padding: 56px 56px 80px 56px;
  @media screen and (min-width: ${twTheme.screens.tablet}) {
    padding: 56px 56px 32px;
  }
  @media screen and (min-width: ${twTheme.screens.desktop}) {
    padding: 140px 60px 40px 60px;
  }

  .sr-only {
    color: ${twTheme.colour.white};
  }
`;

export const CopyrightTextStyle = styled.span`
  display: block;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 17.6px;
  font-family: Roboto;
  margin-top: 42px;

  @media screen and (min-width: ${twTheme.screens.tablet}) {
    margin-top: 28px;
  }

  @media screen and (min-width: ${twTheme.screens.desktop}) {
    display: block;
    margin: 0;
  }
`;
