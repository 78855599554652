import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import determineBackgroundColour from '../../styles/backgroundColour';
import { INavLink } from '../../types/contentfulContentTypes';
import {
  ContentfulRichText,
  renderContentfulRichText,
} from '../../utils/renderContentfulRichText';
import Header from '../MicrositeComponents/Header';
import Footer from '../MicrositeComponents/Footer';

const MicrositeLayout = ({ children, data }: any) => {
  const layoutData: Queries.LayoutDataQuery = useStaticQuery(graphql`
    query MicrositeLayoutData {
      contentfulV2Footer {
        logoImage {
          ...AssetFragment
        }
        logoNavLink {
          ...NavLinkFragment
        }
        navigationText {
          raw
        }
        phoneNumber
        email
        addressText
        copyrightText
        navLinks {
          ...NavLinkFragment
        }
        legalLinks {
          ...NavLinkFragment
        }
      }
      contentfulV2Header(contentful_id: { eq: "AmLOPHp3SWffBOMhO6jAp" }) {
        mainLogo {
          linkUrl
          newTab
          icon {
            file {
              url
            }
            gatsbyImageData(placeholder: BLURRED)
            description
          }
        }
        supportingText
        navigationMenus {
          navLinks {
            ...NavLinkFragment
          }
        }
      }
      contentfulV2NavMenu(contentful_id: { eq: "4kDACJO1Zxbk78L7CzT4ZZ" }) {
        navLinks {
          ...NavLinkFragment
        }
      }
    }
  `);

  const footerPhoneNumberText = layoutData?.contentfulV2Footer
    ?.phoneNumber as string;
  const footerEmailText = layoutData?.contentfulV2Footer?.email as string;
  const footerAddressText = layoutData?.contentfulV2Footer
    ?.addressText as string;
  const copyrightText = layoutData?.contentfulV2Footer?.copyrightText as string;

  const supportingText = layoutData?.contentfulV2Header
    ?.supportingText as string;

  const footerLogoNavLink = layoutData?.contentfulV2Footer
    ?.logoNavLink as INavLink;
  const footerNavigationText = renderContentfulRichText(
    layoutData?.contentfulV2Footer?.navigationText as ContentfulRichText,
  );
  const footerNavLinks = layoutData?.contentfulV2Footer?.navLinks as INavLink[];
  const footerLegalLinks = layoutData?.contentfulV2Footer
    ?.legalLinks as INavLink[];

  const { theme, mobileLogoColour } = data.contentfulV2MicrositePage;

  return (
    <>
      <Header
        data={layoutData.contentfulV2Header as any}
        isHomePage={location.pathname === '/' && true}
        currentPath={location.pathname}
        mobileLogoColour={mobileLogoColour}
        navLinks={layoutData?.contentfulV2Footer?.navLinks as INavLink[]}
        supportingText={supportingText}
      />
      <main id="microsite-content" style={determineBackgroundColour(theme)}>
        {children}
      </main>
      <Footer
        footerLogoNavLink={footerLogoNavLink}
        addressText={footerAddressText}
        navigationText={footerNavigationText}
        navLinks={footerNavLinks}
        legalLinks={footerLegalLinks}
        hideOnDesktop={location.pathname.includes('contact')}
        phoneNumber={footerPhoneNumberText}
        email={footerEmailText}
        copyrightText={copyrightText}
      />
    </>
  );
};

export default MicrositeLayout;
