import React, { ReactNode } from 'react';
import {
  AddressTextStyle,
  FooterGrid,
  FooterWrapper,
  Glyph,
  LogoStyle,
  MainFooter,
  NavLinksStyle,
  NavigationTextStyle,
  LegalLinksStyle,
  PhoneNumberTextStyle,
  CopyrightTextStyle,
} from './styles';
import { INavLink } from '../../types/contentfulContentTypes';
import { PlusGlyph } from '../../assets/staticIcons';
import ContentfulImage from '../ContentfulImage';
import { AnimatedLinks } from '../../styles/animatedLinks';
import NavLink from '../NavLink';
import { useMediaQuery } from 'react-responsive';
import twTheme from '../../styles/theme';

export interface FooterProps {
  footerLogoNavLink: INavLink;
  addressText: string;
  phoneNumber: string;
  navigationText: ReactNode;
  navLinks: INavLink[];
  legalLinks: INavLink[];
  hideOnDesktop?: boolean;
  copyrightText?: string;
}

const Footer: React.FC<FooterProps> = (props) => {
  const {
    addressText,
    footerLogoNavLink,
    hideOnDesktop,
    legalLinks,
    navigationText,
    navLinks,
    phoneNumber,
    copyrightText = 'Thrillworks, Inc',
  } = props;

  const navItems = navLinks.map((navItem) => {
    return (
      <NavLink
        link={navItem}
        key={navItem.id}
        title={navItem.displayText ?? 'Thrillworks'}
      >
        <ContentfulImage image={navItem.icon} alt={navItem.displayText} />
      </NavLink>
    );
  });

  const legalItems = legalLinks.map((legalItem) => {
    return (
      <NavLink link={legalItem} key={legalItem.id}>
        {legalItem.displayText}
      </NavLink>
    );
  });

  const currentYear = new Date().getFullYear();

  const isDesktop = useMediaQuery({
    query: `(min-width: ${twTheme.screens.desktop})`,
  });

  return (
    <MainFooter hideOnDesktop={hideOnDesktop}>
      <FooterWrapper>
        <FooterGrid>
          <div className="textWrapper">
            <LogoStyle>
              <NavLink link={footerLogoNavLink}>
                <span className="sr-only" id="footerLogo">
                  {footerLogoNavLink.displayText}
                </span>
                <ContentfulImage
                  image={footerLogoNavLink.icon}
                  alt={footerLogoNavLink.displayText}
                  aria-labelledby="footerLogo"
                />
              </NavLink>
            </LogoStyle>
            <PhoneNumberTextStyle>
              <a href={`tel:${phoneNumber}`}>{phoneNumber}</a>
            </PhoneNumberTextStyle>
            <AddressTextStyle>{addressText}</AddressTextStyle>
            <NavLinksStyle>{navItems}</NavLinksStyle>
            <LegalLinksStyle>
              {isDesktop && (
                <CopyrightTextStyle>
                  &copy; {currentYear} &mdash; {copyrightText}{' '}
                </CopyrightTextStyle>
              )}
              {legalItems}
            </LegalLinksStyle>
            {!isDesktop && (
              <CopyrightTextStyle>
                &copy; {currentYear} &mdash; {copyrightText}{' '}
              </CopyrightTextStyle>
            )}
          </div>
          <AnimatedLinks className="light">
            <NavigationTextStyle>{navigationText}</NavigationTextStyle>
          </AnimatedLinks>
        </FooterGrid>
      </FooterWrapper>
    </MainFooter>
  );
};

export default Footer;
