import React from 'react';
import { SubNavItem, SubNavContainer } from './styles';
import { INavLink } from '../../../types/contentfulContentTypes';
import isNavLinkActive from '../../../utils/isNavLinkActive';

interface SubNavProps {
  currentPath: string;
  subNavOverview?: INavLink;
  navItems?: INavLink[];
  handleOnClick?: () => void;
  subnavRef?: React.RefObject<HTMLDivElement>;
}

const SubNavPopover = ({
  currentPath,
  subNavOverview,
  navItems,
  handleOnClick,
  subnavRef,
}: SubNavProps) => {
  return (
    <SubNavContainer ref={subnavRef}>
      {subNavOverview && (
        <SubNavItem
          key={subNavOverview.id}
          link={subNavOverview}
          className={`navLink ${
            isNavLinkActive(currentPath, subNavOverview) ? 'active' : ''
          }`}
          onClick={handleOnClick}
        >
          <div className="square"></div>
          {subNavOverview.displayText}
        </SubNavItem>
      )}
      {navItems?.map((navItem) => {
        return (
          <SubNavItem
            key={navItem.id}
            link={navItem}
            className={`navLink ${
              isNavLinkActive(currentPath, navItem) ? 'active' : ''
            }
            ${subNavOverview ? 'child' : ''}
            `}
            onClick={handleOnClick}
          >
            <div className="square"></div>
            {navItem.displayText}
          </SubNavItem>
        );
      })}
    </SubNavContainer>
  );
};

export default SubNavPopover;
