import React from 'react';
import { Link as GatsbyLink } from 'gatsby';
import { IContentLink } from '../types/contentfulContentTypes';
import normalizePathname from '../utils/normalizePathname';

const ProtocolRegEx = new RegExp('^(?:[a-z]+:)?//', 'i');

interface ContentfulLinkProps {
  link?: Maybe<IContentLink | string>;
  [attributes: string]: any;
}
/**
 * This component takes a Contentful Content: Link entry and determines
 * whether it should use an internal GatsbyLink or HTMLAnchorElement.
 */
const ContentfulLink: React.FC<ContentfulLinkProps> = (props) => {
  const { children, link, ...attributes } = props;

  if (!link) {
    // broken link
    return (
      <span title="This link is broken" {...attributes}>
        {children}
      </span>
    );
  } else if (typeof link === 'string') {
    if (ProtocolRegEx.test(link)) {
      return (
        <a
          href={`${link}`}
          target="_blank"
          rel="noopener noreferrer"
          {...attributes}
        >
          {children}
        </a>
      );
    } else if (link.startsWith('mailto:') || link.startsWith('tel:')) {
      return (
        <a href={`${link}`} {...attributes}>
          {children}
        </a>
      );
    } else {
      return (
        <GatsbyLink to={normalizePathname(link)} {...attributes}>
          {children}
        </GatsbyLink>
      );
    }
  } else {
    if (link.externalUrl) {
      const targetProperties = link.openInNewWindow
        ? {
            target: '_blank',
            rel: 'noopener noreferrer',
          }
        : {};
      return (
        <a href={`${link.externalUrl}`} {...targetProperties} {...attributes}>
          {children}
        </a>
      );
    } else if (link.page?.slug) {
      return (
        <GatsbyLink to={normalizePathname(link.page?.slug)} {...attributes}>
          {children}
        </GatsbyLink>
      );
    } else {
      // broken link
      return (
        <span title="This link is broken" {...attributes}>
          {children}
        </span>
      );
    }
  }
};
export default ContentfulLink;
