import styled from 'styled-components';
import twTheme from '../../../styles/theme';

export const MobileMenuContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: ${twTheme.colour.darkGrey};
  color: ${twTheme.colour.white};
  z-index: 102;
  overflow: scroll;
  opacity: 0;

  &.mobileMenu-open {
    opacity: 1;
    transition: opacity 500ms;
  }

  &.mobileMenu-close {
    opacity: 0;
    transition: opacity 500ms;
  }

  @media screen and (min-width: ${twTheme.screens.desktop}) {
    display: none;
  }
`;

export const NavItemsWrapper = styled.nav`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 96px 0 64px;

  .navLink {
    color: ${twTheme.colour.lightGrey};
    text-decoration: none;
    font-family: 'Roboto';
    font-size: 28px;
    font-weight: 300;
    line-height: 150%;

    &:hover,
    &:active,
    &.active {
      color: ${twTheme.colour.mint};
    }
  }
`;

export const SubNavLinkWrapper = styled.div<{ isOpen?: boolean }>`
  display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 32px;
  padding-left: 24px;
  
  a.active:before {
    content: '';
    display inline-block;
    vertical-align: middle;
    height: 6px;
    width: 6px;
    margin-right: 10px;
    background: ${twTheme.colour.mint};
    }
  
  .navLink {
    color: ${twTheme.colour.white};
    text-decoration: none;
    font-family: 'Roboto';    
    font-weight: 400;
    font-size: 20px;
    margin-bottom: 12px;
    &:hover,
    &:active,
    &.active {
      color: ${twTheme.colour.mint};
    }
  }
  @media screen and (min-width: ${twTheme.screens.tablet}) {
    justify-content: center;
  }
`;

export const NavItemContainer = styled.div<{ hasSubNav?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-bottom: 1px solid rgba(244, 244, 244, 0.15);
  width: 100%;
  padding: 24px;
`;

export const NavLinkWrapper = styled.div<{ hasSubNav?: boolean }>`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: space-between;

  a.active::before {
      content: '';
      display inline-block;
      vertical-align: middle;
      height: 6px;
      width: 6px;
      margin-right: 10px;
      background: ${twTheme.colour.mint};
    }
`;

export const SubNavIcon = styled.div<{ isOpen?: boolean }>`
  width: 24px;
  height: 24px;
  padding: 0;
  margin-left: 8px;
  transform: rotate(${({ isOpen }) => (isOpen ? '180deg' : '0')});

  color: ${({ isOpen }) =>
    isOpen ? `${twTheme.colour.mint}` : `${twTheme.colour.white}`};

  &.active {
    color: ${twTheme.colour.mint};
  }
`;
